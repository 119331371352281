define('ember-scroll-to/components/scroll-to', ['exports', 'ember'], function (exports, Em) {

  'use strict';

  var DURATION = 750;
  var EASING = 'swing';
  var OFFSET = 0;

  exports['default'] = Em['default'].Component.extend({
    tagName: 'a',
    href: null,
    duration: DURATION,
    easing: EASING,
    offset: OFFSET,
    attributeBindings: ['href'],

    scrollable: Em['default'].computed(function () {
      return Em['default'].$('html, body');
    }),

    target: Em['default'].computed('href', function () {
      var elem = Em['default'].$(this.get('href'));
      if (!elem) {
        Em['default'].Logger.warn('element ' + this.get('href') + ' couldn\'t be found');
        return;
      }

      return elem.offset().top + this.get('offset');
    }),

    scroll: Em['default'].on('click', function (evt) {
      evt.stopPropagation();
      evt.preventDefault();

      this.get('scrollable').animate({
        scrollTop: this.get('target')
      }, this.get('duration'), this.get('easing'), Em['default'].run.bind(this, this.sendAction, 'afterScroll'));
    })
  });

});