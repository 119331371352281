define('ember-drag-drop/components/sortable-objects', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    dragCoordinator: Ember['default'].inject.service(),
    attributeBindings: ['draggable'],
    draggable: 'true',
    tagName: 'div',
    overrideClass: 'sortable-objects',
    classNameBindings: ['overrideClass'],
    enableSort: true,
    sortableObjectList: Ember['default'].A(),
    dragStart: function dragStart() {
      this.set('dragCoordinator.arrayList', this.get('sortableObjectList'));
    },
    dragOver: function dragOver() {
      //needed so drop event will fire
      return false;
    },
    drop: function drop() {
      this.set('sortableObjectList', this.get('dragCoordinator').getChangedArray());
      this.sendAction('sortEndAction');
    }
  });

});