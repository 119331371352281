define('ember-cli-masonry-grid/components/masonry-grid', ['exports', 'ember-cli-masonry-grid/templates/components/masonry-grid'], function (exports, masonry_grid) {

	'use strict';


	var MASONRY_OPTION_KEYS = Ember.A(['containerStyle', 'columnWidth', 'percentPosition', 'gutter', 'hiddenStyle', 'isFitWidth', 'isInitLayout', 'isOriginLeft', 'isOriginTop', 'isResizeBound', 'itemSelector', 'stamp', 'transitionDuration', 'stagger', 'visibleStyle']);

	exports['default'] = Ember.Component.extend({
		layout: masonry_grid['default'],
		classNames: ['masonry-grid'],

		// masonry default options
		// overriding the default `isInitLayout` value allows us to attach an event for
		// `layoutComplete` before the first render
		isInitLayout: false,
		itemSelector: '.masonry-item',
		attributeBindings: ['masonryGridStyle:style'],

		masonryGridStyle: Ember.String.htmlSafe('position: relative'),

		masonry: null,

		itemClass: Ember.computed('options.itemSelector', function () {
			return this.get('options.itemSelector').replace('.', '');
		}),
		itemSizer: Ember.computed('options.columnWidth', function () {
			if (typeof this.get('options.columnWidth') === 'string') {
				return this.get('options.columnWidth').replace('.', '');
			} else {
				return false;
			}
		}),

		init: function init() {

			this._super.apply(this, arguments);

			this.set('options', this._computeOptions());
		},
		observesLayout: function observesLayout() {
			Ember.run.debounce(this, this._layoutMasonry, 80);
		},
		didRender: function didRender() {
			var _this = this;

			var self = this;
			if (this.isDestroyed) {
				return;
			}

			this._super.apply(this, arguments);

			var masonry = this.get('masonry'),
			    options = this.get('options');

			Ember.run.scheduleOnce('afterRender', this, function () {
				imagesLoaded(_this.get('element'), function () {
					if (masonry) {
						masonry.reloadItems();
					} else {

						if (self.isDestroyed) {
							return;
						}
						masonry = new Masonry(_this.get('element'), options);

						masonry.on('layoutComplete', function (layout) {
							_this.sendAction('layoutComplete', layout);
						});
						_this.set('masonry', masonry);
					}

					masonry.layout();
				});
			});
		},
		willDestroyElement: function willDestroyElement() {

			this._super.apply(this, arguments);
			this._destroyMasonry();
		},
		_computeOptions: function _computeOptions() {
			var self = this,
			    options = this.get('config') ? this.get('config') : this.getProperties(MASONRY_OPTION_KEYS);

			Object.keys(options).forEach(function (key) {
				if (options[key] === 'null') {
					options[key] = null;
				}

				if (options[key] === undefined) {
					delete options[key];
				}
			});

			Ember.set(options, 'methods', {
				reloadItems: function reloadItems() {
					self._reloadItems();
				},
				destroy: function destroy() {
					self._destroyMasonry();
				},
				layout: function layout() {
					self._layoutMasonry();
				},
				getItemElements: function getItemElements() {
					var masonry = self.get('masonry');
					return masonry ? masonry.getItemElements() : [];
				},
				masonry: function masonry() {
					self.didRender();
				}
			});

			Ember.set(options, 'getMasonry', function () {
				var masonry = self.get('masonry');
				return masonry;
			});

			return options;
		},
		_destroyMasonry: function _destroyMasonry() {
			var masonry = this.get('masonry');
			if (masonry) {
				masonry.destroy();
			}
			this.set('masonry', undefined);
		},
		_reloadItems: function _reloadItems() {
			var masonry = this.get('masonry');
			if (masonry) {
				masonry.reloadItems();
			}
		},
		_layoutMasonry: function _layoutMasonry() {
			var masonry = this.get('masonry');
			if (masonry) {
				masonry.layout();
			}
		},
		didInsertElement: function didInsertElement() {

			var self = this,
			    $ele = this.$(),
			    width = $ele.width();

			setInterval(function () {
				if ($ele.width() !== width) {
					width = $ele.width();

					Ember.run.debounce(self, self._layoutMasonry, 150);
				}
			}, 100);
		}
	});

});