define('ember-model-validator/messages', ['exports'], function (exports) {

  'use strict';

  exports['default'] = {
    presenceMessage: "can't be blank",
    absenceMessage: 'must be blank',
    inclusionMessage: 'is not included in the list',
    exclusionMessage: 'is reserved',
    numericalityMessage: 'is not a number',
    mailMessage: 'is not a valid email',
    formatMessage: 'is invalid',
    colorMessage: 'must be a valid CSS hex color code',
    subdomainMessage: 'must be a valid subdomain',
    acceptanceMessage: 'must be accepted',
    zipCodeMessage: 'is not a valid zip code',
    URLMessage: 'must be a valid url',
    wrongLengthMessage: "is the wrong length (should be %@ characters)",
    tooShortMessage: "is too short (minimum is %@ characters)",
    tooLongMessage: "is too long (maximum is %@ characters)",
    mustContainNumberMessage: 'must include a number',
    mustContainSpecialMessage: 'must include one of these special characters: %@',
    mustContainLowerMessage: 'must include a lower case character',
    mustContainCapitalMessage: 'must include an upper case character',
    customValidationMessage: 'is invalid',
    matchMessage: 'must match %@'
  };

});