define('ember-drag-drop/components/draggable-object', ['exports', 'ember'], function (exports, Ember) {

  'use strict';

  exports['default'] = Ember['default'].Component.extend({
    dragCoordinator: Ember['default'].inject.service(),
    tagName: "div",
    overrideClass: 'draggable-object',
    classNameBindings: [':js-draggableObject', 'isDraggingObject:is-dragging-object:', 'overrideClass'],
    attributeBindings: ['draggable'],
    isDraggable: true,
    isSortable: false,
    title: Ember['default'].computed.alias('content.title'),

    draggable: Ember['default'].computed('isDraggable', function () {
      var isDraggable = this.get('isDraggable');

      if (isDraggable) {
        return true;
      } else {
        return null;
      }
    }),

    dragStart: function dragStart(event) {
      if (!this.get('isDraggable')) {
        event.preventDefault();
        return;
      }

      var dataTransfer = event.dataTransfer;

      var obj = this.get('content');
      var id = this.get('coordinator').setObject(obj, { source: this });

      dataTransfer.setData('Text', id);

      if (obj) {
        Ember['default'].set(obj, 'isDraggingObject', true);
      }
      this.set('isDraggingObject', true);
      this.get('dragCoordinator').dragStarted(obj, event, this);
      this.sendAction('dragStartAction', obj);
      if (this.get('isSortable')) {
        this.sendAction('draggingSortItem', obj);
      }
    },

    dragEnd: function dragEnd(event) {
      if (!this.get('isDraggingObject')) {
        return;
      }

      var obj = this.get('content');

      if (obj) {
        Ember['default'].set(obj, 'isDraggingObject', false);
      }
      this.set('isDraggingObject', false);
      this.get('dragCoordinator').dragEnded(event);
      this.sendAction('dragEndAction', obj);
    },

    dragOver: function dragOver(event) {
      if (this.get('isSortable')) {
        this.get('dragCoordinator').draggingOver(event, this);
      }
      return false;
    },
    drop: function drop(event) {
      //Firefox is navigating to a url on drop, this prevents that from happening
      event.preventDefault();
    },

    actions: {
      selectForDrag: function selectForDrag() {
        var obj = this.get('content');
        var hashId = this.get('coordinator').setObject(obj, { source: this });
        this.set('coordinator.clickedId', hashId);
      }
    }
  });

});