define('ember-cli-lightbox/templates/components/light-box', ['exports'], function (exports) {

  'use strict';

  exports['default'] = Ember.HTMLBars.template((function() {
    var child0 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 2,
                "column": 2
              },
              "end": {
                "line": 2,
                "column": 157
              }
            },
            "moduleName": "modules/ember-cli-lightbox/templates/components/light-box.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element1 = dom.childAt(fragment, [0]);
            var morphs = new Array(4);
            morphs[0] = dom.createAttrMorph(element1, 'src');
            morphs[1] = dom.createAttrMorph(element1, 'data-src');
            morphs[2] = dom.createAttrMorph(element1, 'title');
            morphs[3] = dom.createAttrMorph(element1, 'class');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","unbound",[["get","lazyload-src",["loc",[null,[2,41],[2,53]]]]],[],["loc",[null,[2,31],[2,55]]]]]]],
            ["attribute","data-src",["concat",[["subexpr","unbound",[["get","href",["loc",[null,[2,77],[2,81]]]]],[],["loc",[null,[2,67],[2,83]]]]]]],
            ["attribute","title",["concat",[["subexpr","unbound",[["get","data-title",["loc",[null,[2,102],[2,112]]]]],[],["loc",[null,[2,92],[2,114]]]]]]],
            ["attribute","class",["concat",["lazyload ",["subexpr","unbound",[["get","data-class",["loc",[null,[2,142],[2,152]]]]],[],["loc",[null,[2,132],[2,154]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 1,
              "column": 0
            },
            "end": {
              "line": 3,
              "column": 0
            }
          },
          "moduleName": "modules/ember-cli-lightbox/templates/components/light-box.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["block","if",[["get","inlineImage",["loc",[null,[2,8],[2,19]]]]],[],0,null,["loc",[null,[2,2],[2,164]]]],
          ["content","yield",["loc",[null,[2,164],[2,173]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    var child1 = (function() {
      var child0 = (function() {
        return {
          meta: {
            "revision": "Ember@1.13.7",
            "loc": {
              "source": null,
              "start": {
                "line": 4,
                "column": 2
              },
              "end": {
                "line": 4,
                "column": 112
              }
            },
            "moduleName": "modules/ember-cli-lightbox/templates/components/light-box.hbs"
          },
          arity: 0,
          cachedFragment: null,
          hasRendered: false,
          buildFragment: function buildFragment(dom) {
            var el0 = dom.createDocumentFragment();
            var el1 = dom.createElement("img");
            dom.appendChild(el0, el1);
            return el0;
          },
          buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
            var element0 = dom.childAt(fragment, [0]);
            var morphs = new Array(3);
            morphs[0] = dom.createAttrMorph(element0, 'src');
            morphs[1] = dom.createAttrMorph(element0, 'title');
            morphs[2] = dom.createAttrMorph(element0, 'class');
            return morphs;
          },
          statements: [
            ["attribute","src",["concat",[["subexpr","unbound",[["get","href",["loc",[null,[4,41],[4,45]]]]],[],["loc",[null,[4,31],[4,47]]]]]]],
            ["attribute","title",["concat",[["subexpr","unbound",[["get","data-title",["loc",[null,[4,66],[4,76]]]]],[],["loc",[null,[4,56],[4,78]]]]]]],
            ["attribute","class",["concat",[["subexpr","unbound",[["get","data-class",["loc",[null,[4,97],[4,107]]]]],[],["loc",[null,[4,87],[4,109]]]]]]]
          ],
          locals: [],
          templates: []
        };
      }());
      return {
        meta: {
          "revision": "Ember@1.13.7",
          "loc": {
            "source": null,
            "start": {
              "line": 3,
              "column": 0
            },
            "end": {
              "line": 5,
              "column": 0
            }
          },
          "moduleName": "modules/ember-cli-lightbox/templates/components/light-box.hbs"
        },
        arity: 0,
        cachedFragment: null,
        hasRendered: false,
        buildFragment: function buildFragment(dom) {
          var el0 = dom.createDocumentFragment();
          var el1 = dom.createTextNode("  ");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createComment("");
          dom.appendChild(el0, el1);
          var el1 = dom.createTextNode("\n");
          dom.appendChild(el0, el1);
          return el0;
        },
        buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
          var morphs = new Array(2);
          morphs[0] = dom.createMorphAt(fragment,1,1,contextualElement);
          morphs[1] = dom.createMorphAt(fragment,2,2,contextualElement);
          return morphs;
        },
        statements: [
          ["block","if",[["get","inlineImage",["loc",[null,[4,8],[4,19]]]]],[],0,null,["loc",[null,[4,2],[4,119]]]],
          ["content","yield",["loc",[null,[4,119],[4,128]]]]
        ],
        locals: [],
        templates: [child0]
      };
    }());
    return {
      meta: {
        "revision": "Ember@1.13.7",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 5,
            "column": 7
          }
        },
        "moduleName": "modules/ember-cli-lightbox/templates/components/light-box.hbs"
      },
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createComment("");
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes(dom, fragment, contextualElement) {
        var morphs = new Array(1);
        morphs[0] = dom.createMorphAt(fragment,0,0,contextualElement);
        dom.insertBoundary(fragment, 0);
        dom.insertBoundary(fragment, null);
        return morphs;
      },
      statements: [
        ["block","if",[["get","lazyloadActive",["loc",[null,[1,6],[1,20]]]]],[],0,1,["loc",[null,[1,0],[5,7]]]]
      ],
      locals: [],
      templates: [child0, child1]
    };
  }()));

});