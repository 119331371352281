define('ember-poll/services/poll', ['exports'], function (exports) {

  'use strict';



  exports['default'] = Ember.Service.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.set('_polls', Ember.A([]));
    },
    willDestroy: function willDestroy() {
      this.stopAll();
    },
    addPoll: function addPoll(_ref) {
      var interval = _ref.interval,
          callback = _ref.callback,
          label = _ref.label;

      if (interval <= 1) {
        throw new Error('Polling interval must be greater than 1');
      }

      var handle = this._schedule(callback, interval);
      var poll = { handle: handle, callback: callback, interval: interval };
      if (label) {
        poll.label = label;
      }
      this._polls.pushObject(poll);
      return handle;
    },
    startPoll: function startPoll(oldHandle) {
      var newHandle = this._startPoll('handle', oldHandle);
      return newHandle;
    },
    startPollByLabel: function startPollByLabel(label) {
      var newHandle = this._startPoll('label', label);
      return newHandle;
    },
    stopPoll: function stopPoll(handle) {
      if (handle && typeof clearInterval !== 'undefined') {
        clearInterval(handle);
      }
    },
    stopPollByLabel: function stopPollByLabel(label) {
      var poll = this._polls.findBy('label', label);
      if (poll) {
        this.stopPoll(poll.handle);
      }
    },
    stopAll: function stopAll() {
      var handles = this._polls.mapBy('handle');
      handles.forEach(this.stopPoll);
    },
    clearPoll: function clearPoll(handle) {
      var poll = this._polls.findBy('handle', handle);
      this.stopPoll(poll.handle);
      this._polls.removeObject(poll);
    },
    clearPollByLabel: function clearPollByLabel(label) {
      var poll = this._polls.findBy('label', label);
      if (poll) {
        this.clearPoll(poll.handle);
      }
    },
    clearAll: function clearAll() {
      var handles = this._polls.mapBy('handle');
      handles.forEach(Ember.run.bind(this, 'clearPoll'));
    },
    _schedule: function _schedule(fn, interval) {
      if (typeof setInterval !== 'undefined') {
        return setInterval(Ember.run.bind(this, fn), interval);
      }
    },
    _startPoll: function _startPoll(key, value) {
      var poll = this._polls.findBy(key, value);
      if (poll) {
        var callback = poll.callback,
            interval = poll.interval;

        var newHandle = this._schedule(callback, interval);
        return newHandle;
      } else {
        console.warn('No poll was found for ' + key + ' ' + value); // eslint-disable-line
      }
    }
  });

});