define('ember-cli-masonry-grid/components/masonry-widget', ['exports', 'ember-cli-masonry-grid/templates/components/masonry-widget'], function (exports, layout) {

	'use strict';


	exports['default'] = Ember.Component.extend({
		layout: layout['default'],
		classNameBindings: ['itemClass'],
		attributeBindings: ['masonryItemStyle:style', 'masonryItemId:id'],

		masonryItemStyle: Ember.String.htmlSafe('position: absolute'),

		itemClass: Ember.computed('item.masonry.classname', function () {
			var _classname = '';
			if (this.get('item.masonry.classname')) {
				_classname += this.get('item.masonry.classname');
			} else {
				_classname += this.get('grid.itemClass');
			}

			this.get('grid').observesLayout();

			return _classname.replace(/\./g, ' ');
		}),

		masonryItemId: Ember.computed('item.masonry.elementId', function () {
			if (this.get('item.masonry.elementId')) {
				return this.get('item.masonry.elementId').replace('#', '');
			} else {
				this.get('grid').incrementProperty('itemCount');
				return 'masonry-item-' + this.grid.itemCount;
			}
		}),

		init: function init() {
			this._super.apply(this, arguments);

			this.addObserver('item.masonry.stamp', this, 'onStamp');
		},
		onStamp: function onStamp() {
			var stamp = this.get('item.masonry.stamp'),
			    $grid = this.get('grid.masonry');

			if (stamp === true) {
				$grid.stamp(this.getElement());
				$grid.layout();
			} else if (stamp === false) {
				$grid.unstamp(this.getElement());
				$grid.layout();
			}
		},
		getElement: function getElement() {
			return document.getElementById(this.get('masonryItemId'));
		}
	});

});