define('ember-cli-lightbox/components/light-box', ['exports', 'ember', 'ember-cli-lightbox/templates/components/light-box'], function (exports, Ember, layout) {

	'use strict';

	exports['default'] = Ember['default'].Component.extend({
		layout: layout['default'],
		tagName: 'a',
		attributeBindings: ['href', 'lazyload-src', 'data-lightbox', 'data-title', 'data-class'],
		inlineImage: true,
		lazyloadActive: false,
		classNames: ['ember-lightbox'],
		classNameBindings: ['inlineImage']
	});

});